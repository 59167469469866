import { BusinessType } from '@/lib/types/BusinessType.types';
import { Industry } from '@/lib/types/Industry.types';
import { IntendedUseOfFunds } from '@/lib/types/IntendedUseOfFunds.types';
import { Marketing } from '@/lib/types/OffersPayload.types';

export const STORAGE_KEY: string = 'journeyDetails';

export enum ActionType {
  UPDATE_OFFERS_PAYLOAD = 'updateOffersPayload',
  UPDATE_COMPLETED_STAGES = 'updateCompeletedStages',
  UPDATE_OFFER_RESPONSE = 'updateOfferResponse',
  UPDATE_OFFER_SELECTED = 'updateOfferSelected',
  ACCEPT_OFFER = 'acceptOffer',
  ADD_SESSION_ID = 'addSessionId',
  REMOVE_PARTNER = 'removePartner',
  UPDATE_ACCEPT_PAYLOAD = 'updateAcceptPayload',
  REMOVE_SHAREHOLDERS = 'removeShareholders',
  HAS_CONNECTED_OPEN_BANKING = 'hasConnectedOpenBanking',
  UPDATE_CREATE_CODAT_COMPANY_RESPONSE = 'updateCreateCodatCompanyResponse',
  SET_INDICATIVE_QUOTING_FALLBACK = 'setIndicativeQuotingFallback',
  SET_VALIDATED_MID = 'setValidateMID',
  RESET_MID_AND_VOLUMES = 'resetMIDAndVolumes',
  REMOVE_SELECTED_OFFER = 'removeSelectedOffer',
  HAS_SIGNED_ALL_CONTRACTS = 'hasSignedAllContracts',
  EDIT_MODE = 'editMode',
  REMOVE_EDIT_MODE = 'removeEditMode',
  UPDATE_INDIVIDUAL_MEDIUM_CONSENT = 'updateIndividualMediumConsent',
  UPDATE_EXPLICIT_MARKETING_CONSENT = 'updateExplicitMarketingConsent',
  UPDATE_DISCLOSURE = 'updateDisclosure',
  ALLOW_ENTITY_SELECT = 'allowEntitySelect',
  ALLOW_BUSINESS_NAME_SELECT = 'allowBusinessNameSelect',
  ALLOW_PERSONAL_NAME_SELECT = 'allowPersonalNameSelect',
  UPDATE_ACCEPT_PAYLOAD_BODY = 'updateAcceptPayloadBody',
  UPDATE_ACCEPT_RESPONSE = 'updateAcceptResponse',
  UPDATE_SAME_ADDRESS = 'updateSameAddress',
  UPDATE_ORIGINAL_DATA = 'updateOrignalData',
  UPDATE_CONTRACT_DETAILS = 'updateContractDetails',
  UPDATE_EXPANDED_INDUSTRY = 'updateExpandedIndustry',
  SET_APPLICATION_COMPLETE = 'setApplicationComplete',
  SET_JOURNEY_FLOW = 'setJourneyFlow',
  SET_REDIRECT_TO_BCA = 'setRedirectToBca',
  SET_SELECTED_RENEWAL_OFFER = 'setSelectedRenewalOffer',
  ADD_RENEWAL_DATA = 'addRenewalData',
  SET_ACCEPTED_RENEWAL_OFFER = 'setAcceptedRenewalOffer',
}

interface MonthlyRevenues {
  date: string;
  amount: number;
  count: number;
  currency: string;
  indicative: boolean;
}

export interface BusinessFormDetails {
  businessType?: BusinessType;
  businessName?: string;
  businessStartDate?: string;
  emailAddress?: string;
  phoneNumber?: string;
  applicationComms?: boolean;
  industry?: Industry;
  businessAddress?: {
    line1?: string;
    line2?: string;
    townCity?: string;
    postcode?: string;
    state?: string;
    country?: string;
  };
  merchantID?: string;
  intendedUseOfFunds?: IntendedUseOfFunds;
  monthlyRevenues?: MonthlyRevenues[];
  company?: {
    business_type?: string;
  };
  registrationNumber?: string;
}

export interface OwnershipFormDetails {
  firstName?: string;
  surname?: string;
  phoneNumber?: string;
  dob?: string;
  homeAddressLineOne?: string;
  homeAddressLineTwo?: string;
  city?: string;
  state?: string;
  postcode?: string;
  movedInDate?: string;
  emailAddress?: string;
  residentialStatus?: '' | 'Home Owner' | 'Renting';
  ownershipPercentage?: number;
  identificationNumber?: string;
}

export interface PartnerFormDetails {
  applicants?: OwnershipFormDetails[];
}

export interface QuoteFormDetails {
  amountRequested?: number;
}
export interface SessionID {
  sessionId?: string;
}

export interface ReviewFormDetails {
  bureauSearch?: boolean;
  overrideLocaleKey?: string;
}
export interface Offer {
  offer_id: string;
  offer_status: string;
  description: string;
  split_percentage: number;
  term_length: number;
  funded_amount: number;
  repayment_amount: number;
  factor_rate: number;
  currency: string;
}

export interface Product {
  product_id?: string;
  name?: string;
  description?: string;
  decision?: string;
  repayment_mechanism?: string;
  limits?: {
    minimum?: number;
    maximum?: number;
    currency?: string;
  };
  offers?: Offer[];
}

export type OffersResponse = {
  liberis_id: string;
  products: Product[];
  expires_at: string;
};

const actions = {
  updateOffersPayload: (
    data: BusinessFormDetails &
      OwnershipFormDetails &
      PartnerFormDetails &
      ReviewFormDetails &
      QuoteFormDetails &
      Marketing,
  ) => ({
    type: ActionType.UPDATE_OFFERS_PAYLOAD,
    payload: data,
  }),
  updateStage: (data: any) => ({
    type: ActionType.UPDATE_COMPLETED_STAGES,
    payload: data,
  }),
  updateOfferResponse: (data: any) => ({
    type: ActionType.UPDATE_OFFER_RESPONSE,
    payload: data,
  }),
  updateOfferSelected: (data: any) => ({
    type: ActionType.UPDATE_OFFER_SELECTED,
    payload: data,
  }),
  acceptOffer: (data: any) => ({
    type: ActionType.ACCEPT_OFFER,
    payload: data,
  }),
  addSessionId: (data: any) => ({
    type: ActionType.ADD_SESSION_ID,
    payload: data,
  }),
  removePartner: () => ({
    type: ActionType.REMOVE_PARTNER,
  }),
  removeShareholders: () => ({
    type: ActionType.REMOVE_SHAREHOLDERS,
  }),
  updateAcceptPayload: (data: any) => ({
    type: ActionType.UPDATE_ACCEPT_PAYLOAD,
    payload: data,
  }),
  hasSignedAllContracts: (data: any) => ({
    type: ActionType.HAS_SIGNED_ALL_CONTRACTS,
    payload: data,
  }),
  hasConnectedOpenBanking: (data: any) => ({
    type: ActionType.HAS_CONNECTED_OPEN_BANKING,
    payload: data,
  }),
  updateCreateCodatCompanyResponse: (data: any) => ({
    type: ActionType.UPDATE_CREATE_CODAT_COMPANY_RESPONSE,
    payload: data,
  }),
  setIndicativeQuoteFallback: (data: any) => ({
    type: ActionType.SET_INDICATIVE_QUOTING_FALLBACK,
    payload: data,
  }),
  setValidatedMID: (data: any) => ({
    type: ActionType.SET_VALIDATED_MID,
    payload: data,
  }),
  resetMIDAndVolumes: () => ({
    type: ActionType.RESET_MID_AND_VOLUMES,
  }),
  removeSelectedOffer: () => ({
    type: ActionType.REMOVE_SELECTED_OFFER,
  }),
  editMode: () => ({
    type: ActionType.EDIT_MODE,
  }),
  removeEditMode: () => ({
    type: ActionType.REMOVE_EDIT_MODE,
  }),
  updateIndividualMediumConsent: (data: any) => ({
    type: ActionType.UPDATE_INDIVIDUAL_MEDIUM_CONSENT,
    payload: data,
  }),
  updateExplicitMarketingConsent: (data: any) => ({
    type: ActionType.UPDATE_EXPLICIT_MARKETING_CONSENT,
    payload: data,
  }),
  updateDisclosure: (data: any) => ({
    type: ActionType.UPDATE_DISCLOSURE,
    payload: data,
  }),
  allowEntitySelect: (data: any) => ({
    type: ActionType.ALLOW_ENTITY_SELECT,
    payload: data,
  }),
  allowBusinessNameSelect: (data: any) => ({
    type: ActionType.ALLOW_BUSINESS_NAME_SELECT,
    payload: data,
  }),
  allowPersonalNameSelect: (data: any) => ({
    type: ActionType.ALLOW_PERSONAL_NAME_SELECT,
    payload: data,
  }),
  updateAcceptPayloadBody: (data: any) => ({
    type: ActionType.UPDATE_ACCEPT_PAYLOAD_BODY,
    payload: data,
  }),
  updateAcceptResponse: (data: any) => ({
    type: ActionType.UPDATE_ACCEPT_RESPONSE,
    payload: data,
  }),
  updateSameAddress: (data: any) => ({
    type: ActionType.UPDATE_SAME_ADDRESS,
    payload: data,
  }),
  updateOriginalData: (data: any) => ({
    type: ActionType.UPDATE_ORIGINAL_DATA,
    payload: data,
  }),
  updateContractDetails: (data: any) => ({
    type: ActionType.UPDATE_CONTRACT_DETAILS,
    payload: data,
  }),
  updateExpandedIndustry: (data: string) => ({
    type: ActionType.UPDATE_EXPANDED_INDUSTRY,
    payload: data,
  }),
  setApplicationComplete: () => ({
    type: ActionType.SET_APPLICATION_COMPLETE,
  }),
  setJourneyFlow: (data: any) => ({
    type: ActionType.SET_JOURNEY_FLOW,
    payload: data,
  }),
  setRedirectToBca: () => ({
    type: ActionType.SET_REDIRECT_TO_BCA,
  }),
  setSelectedRenewalOffer: (data: any) => ({
    type: ActionType.SET_SELECTED_RENEWAL_OFFER,
    payload: data,
  }),
  addRenewalData: (data: any) => ({
    type: ActionType.ADD_RENEWAL_DATA,
    payload: data,
  }),
  setAcceptedRenewalOffer: (data: any) => ({
    type: ActionType.SET_ACCEPTED_RENEWAL_OFFER,
    payload: data,
  }),
};

export default actions;
